/* ======= Testimonials Section ======= */
.testimonials-section {
    background:$color-primary url('../public_html/assets/images/map.png') no-repeat center center;
    @include background-size(cover);
    padding-top: 90px;
    padding-bottom: 90px;
    color: white;

    .swiper-container {
        background-color: $color-primary;
        margin: auto;
        max-width: 1300px;
        height: auto;

        .card {
            cursor: pointer;
            max-width: 400px;


        }
    }


    .modal-title {
        color: $color-primary;
    }
}


