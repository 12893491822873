

%vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

*,
*::before,
*::after { box-sizing: border-box; }


  .blur:after { opacity: .85; }

  .btnj {

    position: relative;
    display: inline-block;
    border: 6px solid lighten($color-primary, 10%);
    @extend %vertical-align;
    transition: all .1s ease-in-out;
    z-index: 1;
    &.active { box-shadow: 2px 2px 4px rgba(21,58,96, .25); }
    &:focus,
    &:hover { border: 12px solid $color-primary; }
  }

  .btnj,
  .btnj > span {
    cursor: pointer;
  }

  .btnj > span {
   //  min-width: 426px;
  }
@media screen and (min-width: 970px) {
  .btnj > span {
    min-width: 426px;
  }
  #JobVideo{
    border: 6px solid lighten($color-primary, 10%);
  width: 100%;
    height: 100%;
    padding: 10px;

  }
}

  .btnj-inr {
    text-align: center;
    display: inline-block;
    color: white;
    font-weight: 100;
    font-size: 2em;
    line-height: 1;
    text-transform: uppercase;
    background: lighten($color-primary, 10%);
    padding: 1em 2em;
    margin: 6px;
    transition: all .1s ease-in-out;
    &:focus,
    &:hover {
      background: $color-primary;
      padding: 1em 2em;
      margin: 0;
    }
  }

  .txt-a {
    display: inline;
  }

  .txt-b {
    display: none;
  }

  .btnj:focus .btnj-inr,
  .btnj:hover .btnj-inr {
    background: $color-primary;
    padding: 1em 2em;
    width: 100%;
    margin: 0;
  }

  .btnj:focus .txt-a,
  .btnj:hover .txt-a { display: none; }
  .btnj:focus .txt-b,
  .btnj:hover .txt-b { display: inline; }



@media screen and (max-width: 762px) {

  .btnj-inr {
    padding-left: 1em;
    padding-right: 1em;
  }
  .btnj:focus .btnj-inr,
  .btnj:hover .btnj-inr {
    background: $color-primary;
    padding: 1em 1em;
    width: 100%;
    margin: 0;
  }
  #divcol{
    margin-bottom: 15%;
    margin-top: 5%;
    #divSur{
      padding-bottom: 20%;
      #JobVideo{


        position: absolute;
        margin: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: 8%;
        margin-bottom: 0px;
        padding-bottom: -14px;
        bottom: 0px;

      }
    }
  }

}