/*   
 * Template Name: Appkit Landing - Responsive Website Template for Developers
 * Version: 2.1
 * Author: Xiaoying Riley
 * Twitter: @3rdwave_themes
 * License: 97B70924-E74E40FA-A8FF9E06-E96AF00B
 * Website: https://themes.3rdwavemedia.com/
*/

//*************************** Theme Colours****************************/
// Define theme colour scheme
@import "./fontawesome/scss/fontawesome.scss";
@import "./fontawesome/scss/solid.scss";
@import "./fontawesome/scss/brands.scss";
$fa-font-path: "../assets/webfonts";
$color-primary: #002043;
$color-secondary: #cdcdcd;
$color-triacair: #f9f9f9;
$text-color: #002043;
$text-color-secondary: lighten($text-color, 10%);
$text-grey: lighten($text-color-secondary, 0%);
$light-grey: #e1e1e1;
$smoky-white: #cdcdcd;
$divider: #f3f3f3;
$size: 45px;
$whit-white:#cdcdcd;
$black:#000;
$white:#fff;
$bg-hero:#111b22;
$footer-text: rgba(256, 256, 256, 0.6);
$font-family-base: url('assets/font/Mark Simonson - Proxima Nova Alt Regular.otf');
$caret:'\f0da';
$square:'\foc8';

$color-green: #75c181;
$color-blue: #58bbee;
$color-orange: #F8A13F;
$color-pink: #EA5395;
$color-purple: #8A40A7;
$color-yellow: #F2B542;

$facebook: #3b5;
$twitter: #55acee;
$google: #dd4b39;
$github: #444;
$dribbble: #EC4D8B;
$linkedin: #1E6FBA;
$logo-offset: 28px;

//*************************** Theme Styles ****************************/
// Import theme styles
@import "./node_modules/bootstrap/scss/bootstrap";
@import "mixins";



@import "base";
@import "hero";
@import "about";
@import "testimonials";
@import "features";
@import "pricing";
@import "team";
@import "contact";
@import "responsive";
@import "header";
@import "diensten";
@import "visie";
@import "jobs";
@import "button";
