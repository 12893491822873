/* ======= Diensten Section ======= */
.diensten-sectie{
  background:$color-primary;
  color: $smoky-white;
  padding-top: 90px;
  padding-bottom: 90px;

.diensten{
  height: auto;
  background-size: cover;


.FinancieelAdvies
  {
    max-block-size: 100px;

    background-size: auto;
    max-width: 100%;
    height: 50%;
    background-repeat: no-repeat;
    background-position: center;
  }
  .FiscaalAdvies{
    width: 100%;

    height: auto;
    background-position: center;
    background-repeat: no-repeat;
  }
  .OprichtinOnderneming{
    width: 100%;
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
  }
  .herstructurering{
    width: 100%;
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
  }
  .Vastgoedstructuren{
    width: 100%;
    height: auto;
    background-position: center;
    background-repeat: no-repeat;

  }
  .waarderingOnderneming{
    height: auto;
    background-position: center;
    width: 100%;
    background-repeat: no-repeat;

  }
  .DeuDiligenceonderzoek{
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
  .personalFinacePlaning{
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }

  .overnames{
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
  .UwBoekhouding{
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
  .mx-auto{
    padding-bottom: 12.5px;
    padding-top: 12.5px;
  }
  .card{
    border-radius: 0 !important;
    border: none;
    .card-img-overlay{
      transition: all 1s ease-in-out;


      background-color: rgba(0,32 ,67,0.4);
      color: $white;

      .d-flex{
        height: 100%;
        text-align: center;
      }
    }
      .card-img-overlay:hover{


        transition: all 1s cubic-bezier(0,0,1,1);

        background-color: rgba(0,32 ,67,0.8);


      }
  }



  }



  }





