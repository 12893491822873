/* ======= Contact Section ======= */
.contact-section {
    padding-top: 90px;
    padding-bottom: 90px;
    background:$text-color url('../public_html/assets/images/map.png') no-repeat center center;
    color: #fff;
    .section-title {
        margin: 0;
        margin-bottom: 15px;
        font-size: 36px;
        font-weight:300;
        margin-bottom: 30px;
    }

}

.contact-content {
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 60px;
}

.contact-logos{
    font-size: 2em;
 }
.contact-fb-linkedin{
    max-font-size: 20px;
    font-size: 20px
}