/* ======= Hero Section ======= */
.hero-section {
    min-height: 560px;
    background: $bg-hero;
    position: relative;
    
    .figure-holder-wrapper {
        position: absolute;
        left:0;
        bottom: 0;
        width:100%;
        overflow:hidden;
    }
    .figure-holder {
        position: relative;
        height: 560px;
        width: 100%;
       
        .figure-image {
            position: absolute;
            bottom: 0;
            right: 15px;
            z-index:10;
        }
    }
}


.hero-head{
    height: 560px;
    color: #fff;

    .head-inner {
        @media (min-width: 767.98px) { .head-item {
            height: 560px;}
        }
        .head-item {
            height: 100%;
            background-position: center center;
        }
        // banners voor boven aan de pagina mobile first dus eerste foto is bedoeld voor mobiele toestellen zoals smartphones en tablets tweede foto is voor laptops en desktops

        .item-1 {

            background-image: url('../assets/images/HomepageBanner spiegel mobile.png') ;
            @include background-size(cover);
            background-repeat: no-repeat;
            position: relative;
            background-color: rgba(0,0,0,0.2);



            }

        @media (min-width: 767.98px) {
        .item-1 {

            background-image: url('../assets/images/HomepageBanner spiegel.png');
            @include background-size(auto);
            background-repeat: no-repeat;
        }

        }

        //-----------------------------------------------------------------------------//


                .banner-oprichting-onderneming {

                       background-image: url('../assets/images/Oprichtingen.jpg');
                       @include background-size(auto);
                       background-repeat: no-repeat;
                       position: relative;
                       background-size:cover;
                       background-color: #020202;
                       .shade{
                           background-color: rgba(2, 2, 2, 0.2);
                       }


                   }
        .banner-due-diligentenonderzoek {

            background-image: url('../assets/images/DueD.jpg');
            @include background-size(auto);
            background-repeat: no-repeat;
            position: relative;
            background-size:cover;
            background-color: #020202;
            .shade{
                background-color: rgba(2, 2, 2, 0.2);
            }


        }
        .banner-FinancieelAdvies{

            background-image: url('../assets/images/FinancieelAdvies.jpg');
            @include background-size(auto);
            background-repeat: no-repeat;

            background-size: cover;
            background-color: #020202;
            @media(max-width: 767px) {
                background-position: center left;
            }

            .shade {
                background-color: rgba(2, 2, 2, 0.2);
            }


        }

        .banner-FiscaalAdvies {


            @include background-size(auto);
            background-repeat: no-repeat;
            background-position: center top;
            @media (min-width: 2000px) {
                background-position: center top;
                background-size: initial;
                background-image: url('../assets/images/fiscaaladviesUltraWideBanner.jpg');

            }
            @media (max-width: 2000px) {

                background-size: cover;
                background-image: url('../assets/images/FiscaalAdvies.jpg');
            }

            background-color: #020202;

            .shade {
                background-color: rgba(2, 2, 2, 0.2);
            }


        }

        .banner-herstructurering {

            background-image: url('../assets/images/Herstructuring.jpg');
            @include background-size(auto);
            background-repeat: no-repeat;
            position: relative;
            background-size: cover;
            background-color: #020202;

            .shade {
                background-color: rgba(2, 2, 2, 0.2);
            }


        }

        .banner-overnames {

            background-image: url('../assets/images/Overname.jpg');
            @include background-size(auto);
            background-repeat: no-repeat;
            position: relative;
            background-size: cover;
            background-position: center top;
            background-color: #020202;

            .shade {
                background-color: rgba(2, 2, 2, 0.4);
            }


        }

        .banner-personal-finace-planning {

            background-image: url('../assets/images/PersonalFinance.jpg');
            @include background-size(auto);
            background-repeat: no-repeat;
            position: relative;
            background-size: cover;
            background-color: #020202;
            @media(min-width: 2000px) {
                background-image: url("../assets/images/personalFinacePlanningHomeUltraWide.png");
            }

            .shade {
                background-color: rgba(2, 2, 2, 0.2);
            }


        }

        .banner-vastgoed-structuren {

            background-image: url('../assets/images/Vastgoedstructuren.jpg');
            @include background-size(auto);
            background-repeat: no-repeat;
            position: relative;
            background-size: cover;
            background-color: #020202;
            background-position: center top;

            .shade {
                background-color: rgba(2, 2, 2, 0.2);
            }


        }

        .banner-waardering-onderneming {

            background-image: url('../assets/images/Waardering.jpg');
            @include background-size(auto);
            background-repeat: no-repeat;
            position: relative;
            background-size: cover;
            background-color: #020202;

            .shade {
                background-color: rgba(2, 2, 2, 0.2);
            }


        }

        .banner-boekhouding {

            background-image: url('../assets/images/Boekhouding.jpg');
            @include background-size(auto);
            background-repeat: no-repeat;
            position: relative;
            background-size: cover;
            background-color: #020202;
            background-position: center top;

            .shade {
                background-color: rgba(2, 2, 2, 0.2);
            }


        }

        .banner-jobs {

            background-image: url('../assets/images/Banner-Jobs.jpg');
            @include background-size(auto);
            background-repeat: no-repeat;
            position: relative;
            background-size: cover;
            background-color: #020202;

            .shade {
                background-color: rgba(2, 2, 2, 0.2);
            }


        }

        .banner-contact {

            background-image: url('../assets/images/BannerContact.png');
            @include background-size(auto);
            background-repeat: no-repeat;
            position: relative;
            background-position: center top;
            background-size: cover;
            background-color: #020202;

            .shade {
                background-color: rgba(2, 2, 2, 0.2);
            }


        }

        .banner-goededoelen {

            background-image: url('../assets/images/Goede_doelen.jpg');
            @include background-size(auto);
            background-repeat: no-repeat;

            background-size: contain;
            background-color: #020202;
            @media(max-width: 767px) {
                background-position: center left;
            }

            .shade {
                background-color: rgba(2, 2, 2, 0.2);
            }


        }

        .banner-referenties {

            background-image: url('../assets/images/Banner-referenties.jpg');
            @include background-size(auto);
            background-repeat: no-repeat;
            position: relative;
            background-size: cover;
            background-position: center bottom;
            background-color: #020202;

            .shade {
                background-color: rgba(2, 2, 2, 0.2);
            }

        }

        .banner-events {

            background-image: url('../assets/images/Events.jpg');
            @include background-size(auto);
            background-repeat: no-repeat;
            position: relative;
            background-size: cover;
            background-position: center center;
            background-color: #020202;

            .shade {
                background-color: rgba(2, 2, 2, 0.2);
            }


        }
        .banner-davy {

            background-image: url('../assets/images/DavyLievensDesk.jpg');
            @include background-size(auto);
            background-repeat: no-repeat;
            position: relative;
            background-size: cover;
            background-position: left top;
            background-color: #020202;

            .shade {
                background-color: rgba(2, 2, 2, 0.2);
            }


        }

        .banner-leen {

            background-image: url('../assets/images/Leentje.jpg');
            @include background-size(auto);
            background-repeat: no-repeat;
            position: relative;
            background-size: cover;
            background-position: left top;
            background-color: #020202;

            .shade {
                background-color: rgba(2, 2, 2, 0.2);
            }


        }

        .banner-marijke {

            background-image: url('../assets/images/marijke engelbosdesk.jpg');
            @include background-size(auto);
            background-repeat: no-repeat;
            position: relative;
            background-size: cover;
            background-position: left top;
            background-color: #020202;

            .shade {
                background-color: rgba(2, 2, 2, 0.2);
            }


        }

        .banner-steve {

            background-image: url('../assets/images/Steve.jpg');
            @include background-size(auto);
            background-repeat: no-repeat;
            position: relative;
            background-size: cover;
            background-position: left top;
            background-color: #020202;

            .shade {
                background-color: rgba(2, 2, 2, 0.2);
            }


        }

        .banner-petra {

            background-image: url('../assets/images/Petra.jpg');
            @include background-size(auto);
            background-repeat: no-repeat;
            position: relative;
            background-size: cover;
            background-position: left top;
            background-color: #020202;

            .shade {
                background-color: rgba(2, 2, 2, 0.2);
            }


        }





        .heading {
            font-size: 30px;
            line-height: 1.4;
            font-weight: bold;
            margin-top:25%;
            margin-bottom: 15px;
        }
        .intro {
            font-size: 18px;
            & + .btn {
                margin-top: 15px;
            }
        }
    }

}


