// Small devices (landscape phones, less than 768px)
@media (max-width: 767px) {
    .navbar-nav .show .dropdown-menu {

        float: none;
        width: 100%;
        margin-top: 0;
        background-color: white;
        border: 0;
        box-shadow: none;

    }
    .navbar-nav  .dropdown-menu {

        float: none;
        width: 100%;
        margin-top: 0;
        background-color: white;
        border: 0;
        box-shadow: none;

    }

}
@media (max-width: 767.98px) { 
    .navbar-collapse {
        border-top: none;
        @include box-shadow(none);
        width: 100%;
        left: 0;
        top: 60px;
        position: absolute;
        background: rgba(darken($text-color, 10%), 0.9);
        z-index: 40;
    }
    
    .header.header-scrolled .main-nav .nav .nav-link.active{


        border: none;
    }

    
    .main-nav .navbar-collapse .nav-item {
        margin-right: 0;
    }
    
    .main-nav .navbar-collapse .nav-item a {
        text-align: center;
    }

    .hero-section .figure-image {
        width: 300px;
    }
    .hero-head .head-inner .heading {
        font-size: 26px;
    }
    
    .hero-head .head-inner .item-content-inner {
        padding-top: 250px;
    }
    .hero-section .figure-holder .figure-image {
        width: inherit;
        bottom: 0;
        top: inherit;
        right: inherit;
        left:15px;
        max-width: 320px;
    }

    .technologies li {
        margin-bottom: 15px;
    }
    
    .members-wrapper .item {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .hero-head {
        height: 660px;
    }
    .hero-section .head-inner {
        height: 660px;
    }
    
    .hero-head .head-inner .item {
        height: 660px;
    }
    
    .testimonials-section .item .profile-image {
        width: 60px;
        height: 60px;
    }
    .testimonials-section .item .quote {
        margin-left: 85px;
        padding: 15px;
    }
    .testimonials-section .item.item-reversed .quote {
        margin-right: 85px;
    }
    


}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .feature-nav {
        text-align: left;
        padding-left: 30px;
    }

    .hero-section .figure-holder .figure-image {
        width: 360px;
        bottom: inherit;
        top: 230px;
    }
    .hero-head .head-inner .item-content-inner {
        max-width: 300px;
        padding-top: 120px;
    }
    


}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
	.main-nav .nav .nav-item {
		margin-right: 25px;
	}
    .feature-nav  {
        margin-top: 30px;
        padding-left: 15px;
    }

    .hero-section .figure-holder .figure-image {
        width: 420px;
        bottom: inherit;
        top: 230px;
    }
    .hero-head .head-inner .item-content-inner {
        max-width: 500px;
    }

    
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .hero-section .figure-holder .figure-image {
        width: 570px;
        bottom: 0;
        top: inherit;
    }
}
.dropdown{

    .show{
        transform: translate3d(0px, 51px, 0px) !important;
    }
}
.dropdown-menu{
    transform: translate3d(0px, 51px, 0px) !important;
    color: black !important;
}
@media (max-width: 768px) {
    .nav-link{
        color: white !important;
    }

}





