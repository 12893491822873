body {
  font-family: $font-family-base, 'Open Sans', arial, sans-serif;
	color: $text-color;
	font-size: 14px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

p {
	margin-bottom: 15px;
	line-height: 1.5;
}

a {
	color: darken($color-primary, 5%);
	@include transition (all 0.4s ease-in-out);
	&:hover {
		text-decoration: underline;
		color: darken($color-primary, 10%);
	}
	&:active {
		text-decoration: none;
	}
	&:focus {
		text-decoration: none;
	}
}

.btn, a.btn {
	@include transition (all 0.4s ease-in-out);
	font-weight: 600;
	font-size: 14px;
	line-height: 1.5;
	.fa {
		margin-right: 5px;
	}
}

.btn-cta, a.btn-cta {
    font-weight: bold;
    font-size: 16px;
    padding: 10px 30px;
}

.btn-primary {
	background: $color-primary;
	border: 1px solid $color-primary;
	color: #fff;
	&:hover, &:focus, &:active, &.active, &.hover {
		background: darken($color-primary, 5%);
		color: #fff;
		border: 1px solid darken($color-primary, 5%);
	}

}
@media(max-width: 767px ) {
  .logo-icon{
    height: 25px;
    width: 25px;
    background-color: $white;
    border-radius: 50%;
    display: inline-block;
  }

}







/* ======= Header ======= */

.header {

    position: fixed;
    width: 100%;
  z-index: 30;
    height: 60px;
    @include transition (none);

    &.header-scrolled {
        background: #fff;
        @include box-shadow(0 0 4px rgba(0, 0, 0, 0.5));
        a {
            color: $white;

        }
      @media (max-width: 767px) {
        a{
          color: $white;
        }

      }




         .logo {
           color: $smoky-white;
           padding-top: 14px;

           .logo-icon-wrapper {
             background: $smoky-white;
             width: 90px;
             height: 50px;

             text-align: center;
           }

           .logo-icon-wrapper-shadow {
             background: $white;
             position: relative;
             top: $logo-offset;
             width: $size;
             height: $size;
             border-bottom-left-radius: $size * 2;
             border-bottom-right-radius: $size * 2;
             box-shadow: 0 4px 3px 0 rgba(0, 0, 0, 0.5);
             text-align: center;
           }

           .logo-icon {
             width: 80px;
             height: 80px;
             margin-right: 0;
             position: relative;
             top: -$size+($size/2)-10;


           }

           .bfc {
             width: 80px;
             height: 70px;
             margin-right: 0;
             position: relative;
             top: -$size+($size/2)-10;
             left: -3px;

           }
         }
      @media (max-width: 767.98px){
      .logo {
        color: $smoky-white;
        padding-top: 14px;

        .logo-icon-wrapper {
          background: $smoky-white;
          width: 0px;
          height: 0px;

          text-align: center;
        }
        .logo-icon-wrapper-shadow {
          background: $white;
          position: relative;
          top: 0;
          width: 0;
          height: 0;
          box-shadow: none;


        }

        .logo-icon {
          width: 40px;
          height: 40px;
          margin-right: 0;
          position: relative;
          top: 0;


        }

        .bfc {
          width: 40px;
          height: 35px;
          margin-right: 0;
          position: relative;
          top: 0;

        }
      }
    }



        .main-nav .nav .nav-link {
            color: $text-grey;
            &:hover {
	            color: darken($text-grey, 5%);
            }
        }
        .main-nav .nav .nav-link.active {
          color:  $color-primary;

            border-bottom: 4px solid $color-primary;
        }

    }
    a {
        color: #fff;
        @include transition (none);
        &:hover {
            text-decoration: none;
        }
    }
    .logo {
        margin: 0;
        display: inline-block;
      float: left;
      font-size: 28px;
      padding-top: 15px;

      .logo-icon-wrapper {
        margin-right: 3px;
        position: relative;
        display: inline-block;
        top: -3px;
      }

      .logo-icon {
        width: 80px;
        height: 80px;

      }

      .bfc {
        width: 80px;
        height: 70px;
      }

      .highlight {
        font-weight: 800;
      }
    }
  @media (max-width: 767.98px){
    .logo {
      margin: 0;
      display: inline-block;
      float: left;
      font-size: 28px;
      padding-top: 15px;
      .logo-icon-wrapper {
        margin-right: 3px;
        position: relative;
        display: inline-block;
        top: -3px;
      }

      .logo-icon {
        width: 40px;
        height: 40px;
      }

      .bfc {
        width: 40px;
        height: 35px;
      }

      .highlight {
        font-weight: 800;
      }
    }

  }
}


.main-nav {
    margin-top: 6px;
    .navbar-toggler {
        margin-right: 0;
        margin-top: 0;
        background: none;
        position: absolute;
        padding: 8px 10px;
        right:10px;
        top: 10px;
        background: rgba(0, 0, 0, 0.6);

        &:focus {
            outline: none;
        }
        .icon-bar {
	        display: block;
            background-color: #fff;
            height: 3px;
            width: 22px;
            @include border-radius(1px);
            & + .icon-bar {
	            margin-top: 4px;

            }
        }
        &:hover {
            .icon-bar {
                background-color: #fff;
            }
        }
    }

    .nav {
        .nav-item{
            font-weight: normal;
            font-size: 14px;
            margin-right: 10px;


          .nav-link {
                color: rgba(256, 256, 256, 0.65);
                font-weight: 700;
                &.active {
                    position: relative;
                    background: none;
                    color: #fff;

                }

            &:hover {
                    color: #fff;
              background: none;
                }
                &:focus {
                    outline: none;
                    background: none;
                }
                &:active {
                    outline: none;
                    background: none;
                }
          }

            &:last-child {
                margin-right: 0;
            }
        }


    }
}

.nav>li>a {
    padding-left: 5px;
    padding-right: 5px;
}

.nav-link {
	padding: 15px;
}

/* ====== Footer ====== */
.footer {
    background: darken($text-color, 15%);
  color: $footer-text;
    padding: 15px 0;
    a {
      color: $footer-text;
    }
    .fa-heart {
        color: $color-pink;
    }
}
.dropdown-item{
  color: $black !important;


}

.contact-info {
  background-color: $color-secondary;
  padding-top: 20px;
  padding-bottom: 20px;
}

.fb_linkedin {

  max-font-size: 40px;
  font-size: 3.5vw;
}

.contact-info {
  .container {
    .row {
      .qfor {
        img {
          max-height: 130px;
          min-height: 90px;
          height: 6vw;
        }
      }
    }
  }
}

.contact-info {
  .container {
    .row {
      .inhoud {
        .row {

          ul {
            list-style: none;
            padding: 0;
          }

          li {

            padding-left: 1.3em;

          }

          li:before {
            @extend %fa-icon;
            @extend .fas;
            content: $caret; /* FontAwesome Unicode */
            display: inline-block;
            margin-left: -1.3em; /* same as padding-left set on li */
            width: 1.3em; /* same as padding-left set on li */
          }
          li:hover{
            padding-left: 1.3em;

          }
          li:before:hover
          {
            @extend %fa-icon;
            @extend .fas;
            content: $caret; /* FontAwesome Unicode */
            margin-left: -1.3em; /* same as padding-left set on li */
            width: 1.3em; /* same as padding-left set on li */

          }


        }
        @media(max-width: 680px ){
          .row{
            display: none;
          }

        }

      }
    }
  }
}
#nosymbol{
  list-style-type:none ;
  padding-left:0;
}
#middenlijnreflinks{
      @media (min-width: 767.98px){
        border-right-width: 1px;
        border-right-style: solid;

      }

    }
#middenlijnrefrechts{
  @media (min-width: 767.98px){
    border-left-width: 1px;
    border-left-style: solid;
  }}
.lijnlinks{
  @media (min-width: 767.98px){
    border-right-width: 1px;
    border-right-style: solid;

  }

}
.lijrechts{
  @media (min-width: 767.98px){
    border-left-width: 1px;
    border-left-style: solid;
  }}
.direct-contact{
  background-color: $color-secondary;
}
.personeelfont{
  font-size: 500px;
  font-family: 'Open Sans', arial, sans-serif;

}



