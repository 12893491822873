/* ======= About Section ======= */
.about-section {
    padding-top: 90px;
  padding-bottom: 90px;
    background-color: lighten($color-primary, 90%);

    background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%23eee4ff' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
    section-title {
        margin: 0;
        margin-bottom: 15px;
        font-size: 36px;
        font-weight:300;
    }
    .intro {
        color: $text-grey;
        font-size: 16px;
        max-width: 760px;
        margin: 0 auto;
        margin-bottom: 60px;
    }
    
    .items-wrapper {
        margin-bottom: 60px;
        .figure-holder {
            min-height: 170px;
            margin-bottom: 15px;
            vertical-align:bottom;
        }
        .item-inner {
            padding: 30px;
        }
        .item-title {
            color: $color-primary;
            font-size: 18px;
            font-weight: bold;
            margin:0;
            margin-bottom: 15px;
        }
        .item-desc {
            color: $text-color-secondary;
        }
    }
    
    
}

.technologies {
    margin-bottom: 90px;
    .list-inline-item {
        margin-right: 15px;
        &:last-child {
            margin-right: 0;
        }
    }
}

.testimonials {
    background: $color-primary;
}


.testimonials-inner {
    padding: 60px 30px;
    position: relative;
    max-width: 800px;
    
    
    .profile-holder {
        position: absolute;
        left: 30px;
        text-align: center;
    }
    
    .profile-image {
        display: inline-block;
        margin: 0 auto;
    }
    .quote {
        background: rgba(0, 0, 0, 0.2);
        color: #fff;
        margin-left: 130px;
        border-left: none;
        margin-bottom: 0;
        font-size: 14px;
        min-height: 100px;
        @include border-radius(4px);
        position: relative;
        padding:15px;
        &:before {
            position: absolute;
            left: -10px;
            top:30px;
            content:"";
            width: 0; 
        	height: 0; 
        	border-top: 10px solid transparent;
        	border-bottom: 10px solid transparent; 
        	border-right:10px solid rgba(0, 0, 0, 0.2); 
        }
    }
}

